









































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "FundProjectModifyApplyList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectModifyApplyList extends Vue {
  projectType: any = [];
  typeList: any = [];

  queryForm: any = {
    name: "",
    projectType: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectType",
      })
      .then((res) => {
        this.projectType = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundProjectModifyApply.getAll(params);
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundProjectModifyApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "fundProjectCreate" });
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundProjectModifyApplyDetail",
      params: { id: row.id!.toString() },
    });
  }
}
